import React, { ChangeEvent, useCallback, useRef, useState } from 'react';
import { CategoriaProps } from './protocols';
import { InputContainer } from './styles';
import { Form, Spinner } from 'react-bootstrap';
import { useInfiniteQuery, useQueryClient } from 'react-query';
import { Controller } from 'react-hook-form';
import { toast } from 'react-toastify';
import api from '~/services/api';
import useDebounce from '~/hooks/useDebounce';
import { Lista } from './Lista';

/**
 * @param {CategoriaProps} Categoria
 */

export const Categoria: React.FC<CategoriaProps> = ({
  label = '',
  placeholder = '',
  ListaWidth = '100%',
  name,
  register,
  isError,
  control,
  changeSelected,
  tipoCategoria = 'Ambos',
  retriever,
  ...rest
}: CategoriaProps) => {
  /**
   * debouncedFn
   * Hook de Debounce usado para efetuar a busca após digitação do usuário
   */
  const { debouncedFn } = useDebounce();
  /**
   * inputRef
   * Ref do input utilizado para setar a label da opção selecionada no input
   */
  const inputRef: any = useRef(null);
  /**
   * selected, setSelected
   * Opção selecionada da lista
   */
  const [selected, setSelected] = useState<any>({
    label: undefined,
    value: undefined,
  });

  /**
   * isOpen
   * Renderiza ou não a lista
   */
  const [isOpen, setIsOpen] = useState<boolean>(false);
  /**
   * filter
   * Filtro passado para a api
   */
  const [filter, setFilter] = useState<string>('');

  /**
   * limit
   * Quantidade de registros buscados por vez na api
   */
  const limit = 10;
  /**
   * fetchData
   * Método que se comunica com a api
   */

  const tipoCategoriaMap: any = {
    Receita: 0,
    Despesa: 1,
    Ambos: 2,
  };
  const [categoriaTemp, setCategoriaTemp] = useState<number | null>(
    tipoCategoriaMap[tipoCategoria],
  );

  const filterCategorias = useCallback(
    (options: any[]): any[] => {
      const filteredOptions = options.filter((item) =>
        item.label.toLowerCase().includes(filter.toLowerCase()),
      );
      return filteredOptions;
    },
    [filter],
  );

  const fetchData = useCallback(
    async ({ pageParam = 1 }): Promise<any> => {
      try {
        const cache_categorias: any = queryClient.getQueryData(
          `cache_categorias_${categoriaTemp}`,
        );

        if (
          cache_categorias &&
          tipoCategoriaMap[tipoCategoria] === categoriaTemp
        ) {
          if (retriever) {
            const options = cache_categorias.data ? cache_categorias.data : [];
            const selectedRetriever = options.filter(
              (d: any) => d.value === retriever,
            );
            changeSelected({
              value: selectedRetriever[0].value,
              label: selectedRetriever[0].label,
            });
          }

          return {
            ...cache_categorias,
            data: filterCategorias(cache_categorias.data),
          };
        }

        const tipo_categoria = tipoCategoriaMap[tipoCategoria];
        const { data: dataFields } = await api.get(
          `/categorias/${tipo_categoria}`,
          {
            params: {
              page: pageParam,
              limit,
              filter,
            },
          },
        );
        setCategoriaTemp(tipo_categoria);

        const options = dataFields.data
          .filter((d: any) => d.flg_inativo !== true)
          .map((dt: any) => {
            return {
              value: dt.cod_subcategoria,
              label: `${dt.descricao}`,
              ...dt,
            };
          });

        const dispesas = [];
        const receita = [];
        const outros = [];

        for (let index = 0; options.length > index; index++) {
          const splits = options[index].label.split(' ', 2);

          if (splits[0] === '(+)') {
            receita.push(options[index]);
          } else if (splits[0] === '(-)') {
            dispesas.push(options[index]);
          } else {
            outros.push(options[index]);
          }
        }
        const optionsAux = [
          {
            label: 'Nenhuma',
            value: undefined,
          },
          ...receita,
          ...dispesas,
          ...outros,
        ];
        queryClient.setQueryData(`cache_categorias_${categoriaTemp}`, {
          ...dataFields,
          data: optionsAux,
        });
        if (retriever) {
          const selectedRetriever = optionsAux.filter(
            (d: any) => d.value === retriever,
          );
          changeSelected({
            value: selectedRetriever[0].value,
            label: selectedRetriever[0].label,
          });
        }
        return {
          ...dataFields,
          data: optionsAux,
        };
      } catch (error: any) {
        if (error.data?.message) {
          toast.error(error.data.message);
        } else {
          toast.error(String(error));
        }
        return {
          count: '0',
          data: [],
          fields: [],
          pagination: {
            lastPage: 0,
            prevPage: 1,
            startPage: 1,
          },
          success: true,
        };
      }
    },
    [isOpen, filter, filterCategorias],
  );

  /**
   * useInfiniteQuery
   * Hook do react-query para listagem infinita de dados
   */
  const queryClient = useQueryClient();
  const { data, hasNextPage, fetchNextPage, isFetching, refetch } =
    useInfiniteQuery(name, ({ pageParam = 1 }) => fetchData({ pageParam }), {
      refetchOnWindowFocus: false,
    });

  return (
    <InputContainer>
      <Form.Group>
        <Form.Label>{label}</Form.Label>
        <Controller
          name={name}
          control={control}
          defaultValue=""
          render={({ field: { value } }) => {
            if (inputRef && inputRef.current) {
              /** A condicional abaixo limpa o input */
              if (
                value.label !== selected.label &&
                inputRef.current.placeholder !== placeholder
              ) {
                inputRef.current.placeholder = placeholder;
              }

              if (value.label) {
                inputRef.current.placeholder = `${value.label}`;
              }
              if (!value.label) {
                inputRef.current.placeholder = placeholder;
              }
            }
            return (
              <>
                <div className="select-container">
                  <label>
                    <input
                      {...register(name)}
                      type="text"
                      className={
                        isError ? 'form-control is-invalid' : 'form-control'
                      }
                      placeholder={placeholder}
                      onChange={(event: ChangeEvent<HTMLInputElement>) => {
                        event.target.value = event.target.value.toUpperCase();
                        setFilter(event.target.value.toUpperCase());

                        if (event.target.value === '') {
                          queryClient.removeQueries(name);
                        }
                        debouncedFn(() => refetch(), 500);
                      }}
                      autoComplete="off"
                      onClick={(event: any) => {
                        if (selected.label) {
                          event.target.placeholder = selected.label;
                        }
                        event.target.value = '';
                        setIsOpen(true);
                      }}
                      ref={inputRef}
                      {...rest}
                    />
                    <div className="drop-indicator">
                      <span role="img" aria-label="open">
                        {filter && isFetching ? (
                          <Spinner
                            animation="border"
                            size="sm"
                            className="spinner"
                          />
                        ) : (
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            role="presentation"
                          >
                            <path
                              d="M8.292 10.293a1.009 1.009 0 000 1.419l2.939 2.965c.218.215.5.322.779.322s.556-.107.769-.322l2.93-2.955a1.01 1.01 0 000-1.419.987.987 0 00-1.406 0l-2.298 2.317-2.307-2.327a.99.99 0 00-1.406 0z"
                              fill="currentColor"
                              fillRule="evenodd"
                            />
                          </svg>
                        )}
                      </span>
                    </div>
                  </label>
                </div>
                {isOpen && (
                  <Lista
                    listaWidth={ListaWidth}
                    inputRef={inputRef}
                    setIsOpen={setIsOpen}
                    setFilter={setFilter}
                    hasNextPage={hasNextPage}
                    fetchNextPage={fetchNextPage}
                    data={data}
                    selected={selected}
                    setSelected={setSelected}
                    isFetching={isFetching}
                    queryName={name}
                    changeSelected={changeSelected}
                    queryClient={queryClient}
                  />
                )}
              </>
            );
          }}
        />
      </Form.Group>
    </InputContainer>
  );
};
